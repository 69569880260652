import {IconButton, type IconButtonProps} from '@primer/react'
import React from 'react'
import {XIcon} from '@primer/octicons-react'
import styles from './FacetNegateButton.module.css'

interface FacetNegateButtonProps extends Pick<IconButtonProps, 'onClick'> {
  ariaLabel: string
}

export default React.forwardRef(FacetNegateButtonWithRef)

function FacetNegateButtonWithRef(
  {ariaLabel, onClick}: FacetNegateButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>,
): JSX.Element | null {
  return (
    <IconButton
      aria-label={ariaLabel}
      icon={XIcon}
      onClick={onClick}
      ref={ref}
      tabIndex={-1}
      variant="invisible"
      className={styles.TrailingNegateButton}
    />
  )
}

try{ FacetNegateButtonWithRef.displayName ||= 'FacetNegateButtonWithRef' } catch {}