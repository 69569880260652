import React from 'react'
import {ActionList, Box, Truncate} from '@primer/react'
import {
  type FacetEntry,
  FacetKindFilter,
  FacetKindLanguage,
  FacetKindPath,
  FacetKindState,
  FacetKindPackageType,
} from '../../types/blackbird-types'
import {useNavigateToQuery} from '../../hooks/use-navigate-to-query'
import LanguageCircle from '../LanguageCircle'
import FacetNegateButton from './FacetNegateButton'
import {packageIconFromName} from '../PackageIcons'
import {GitHubAvatar} from '@github-ui/github-avatar'
import styles from './FacetOption.module.css'

import {FileDirectoryIcon, PlusCircleIcon} from '@primer/octicons-react'
import {formatPackageTitle} from '../../utilities/format-type-title'

interface FacetOptionProps {
  autofocus: boolean
  item: FacetEntry
  allowNegation: boolean
  kind: string
  onSelect: (event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => void
  href?: string
  mobile?: boolean
}

export default function FacetOption({autofocus, kind, item, href, onSelect, allowNegation, mobile}: FacetOptionProps) {
  const navigateToQuery = useNavigateToQuery()

  const negateButtonRef = React.useRef<HTMLButtonElement>(null)
  const linkItemRef = React.useRef<HTMLElement | null>(null)

  let leadingVisual = null
  let itemName = item.name

  switch (kind) {
    case FacetKindLanguage:
      leadingVisual = <LanguageCircle color={item.language_color} />
      break
    case FacetKindPath:
      leadingVisual = <FileDirectoryIcon size={16} />
      break
    case FacetKindFilter:
      allowNegation = false
      leadingVisual = <PlusCircleIcon />
      break
    case FacetKindState:
      allowNegation = false
      leadingVisual = item.visual
      break
    case FacetKindPackageType:
      allowNegation = false
      leadingVisual = packageIconFromName(item.name, `_filter_${mobile ? 'mobile' : 'desktop'}`)
      itemName = formatPackageTitle(itemName)
  }

  if (item.owner) {
    leadingVisual = <GitHubAvatar size={16} src={`/${item.owner}.png`} />
  }

  return (
    <ActionList.LinkItem
      as={item.shouldNavigate ? 'a' : 'button'}
      href={item.shouldNavigate ? href : undefined}
      data-react-autofocus={autofocus ? true : null}
      onClick={onSelect}
      onAuxClick={onSelect}
      tabIndex={0}
      onKeyDown={(event: React.KeyboardEvent) => {
        // Not a hothey, but for navigation to the negate button
        // eslint-disable-next-line @github-ui/ui-commands/no-manual-shortcut-logic
        if (event.key === 'ArrowRight') {
          negateButtonRef.current?.focus()
        }
        // Not a hothey, but for navigation to the negate button
        // eslint-disable-next-line @github-ui/ui-commands/no-manual-shortcut-logic
        if (event.key === 'ArrowLeft') {
          linkItemRef.current?.focus()
        }
      }}
      ref={(ref: HTMLElement | null) => {
        linkItemRef.current = ref
      }}
      className={styles.FacetOption}
    >
      <ActionList.LeadingVisual>{leadingVisual}</ActionList.LeadingVisual>
      <Box
        sx={{display: 'flex', alignItems: 'center'}}
        // Tell Google translate to not bother
        translate="no"
      >
        <div className={styles.TruncateWrap}>
          <Truncate title={itemName} maxWidth={`100%`}>
            {itemName}
          </Truncate>
        </div>
        {allowNegation && (
          <div className={styles.TrailingNegateButtonWrap}>
            <FacetNegateButton
              ariaLabel={`Exclude ${itemName}`}
              ref={negateButtonRef}
              onClick={event => {
                if (!item || event.defaultPrevented) return
                const languageFilter = `NOT ${item.query}`
                navigateToQuery(undefined, languageFilter)
                event.preventDefault()
              }}
            />
          </div>
        )}
      </Box>
    </ActionList.LinkItem>
  )
}

try{ FacetOption.displayName ||= 'FacetOption' } catch {}